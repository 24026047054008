import * as React from "react"
import Layout from "../../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Helmet from 'react-helmet'
const history = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'single-page whitemain'
    }}
/>
  <Layout pageTitle="history">
    <section id="main">
      <div id="worktitle">
        <span>
          <h1>History Month</h1>
          <span className="highlight"></span>
        </span>
        <span>Royal Historical Society of Victoria</span>
      </div>
    </section>

    <section id="work">
      <div className="work">
          <div className="picture workpicture">
		  	<img src={'/img/works/history-week-horses.jpg'} alt="History Month" />

          </div>

        <div className="seeWork">
          <div className="seeWork__content">
            <div className="icons">
              <img className="picture" src={'/img/single/wordpress-icon.png'} width="139" height="87" alt="Wordpress" />

            </div>
            <a className="button visitwebsite" href="http://historymonth.org.au/" target="_blank" rel="noreferrer"><span className="seemore"><i>&#xe801;</i></span>Visit Website</a>
          </div>
        </div>

      </div>
    </section>

    <section id="single-content">
      <div className="single-content">
        <p>Get ready to dive into Victoria’s history this History Month!</p>
        <p>Tobstar deliver customised WordPress development responsive for desktop, smartphone and tablet. Customised events management powered by WordPress with our content management interface.</p>
      </div>

      <div className="project-image">
        <div className="left-col">
          <img className="picture" src={'/img/single/historymonthorgau.jpg'} alt="Desktop View" />
        </div>
        <div className="right-col">
          <img className="picture" src={'/img/single/historymonthorgau_mobile.jpg'} alt="Mobile View" />
      </div>
      </div>
    </section>


    <section id="post-pagination">
      <div className="post-pagination">
        <div className="previous">
          <AniLink cover to="/work/bills-forecast" bg="#00ccd4" direction="right" duration={0.76} className="button project prev">Prev Work</AniLink>
          <p className="project-name">Bills Forecast</p>
        </div>
        <div className="next">
          <AniLink cover to="/work/word-of-mouth-technology" bg="#00ccd4" direction="right" duration={0.76} className="button project next">Next Work</AniLink>
          <p className="project-name">Word of Mouth Technology</p>
        </div>
      </div>
    </section>
</Layout>

  </>
  )
}

export default history


export const Head = () => (
  <>
    <title>History Month - Tobstar</title>
<meta name="description" content="Get ready to dive into Victoria’s history this History Month!" />
  </>
)